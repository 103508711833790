import * as React from 'react';
import Helmet from 'react-helmet';
// import Logo from '../images/logo.svg';
import './index.scss';
import { Footer } from '../components/molecules/footer/footer';
import NavBar from '../components/molecules/navbar/navbar';
import { StaticImage } from 'gatsby-plugin-image';
import { HOME_ROUTE } from '../utils/constants/routes';
import { Link } from '../components/atoms/link/link';

const NotFoundPage = () => {
  const h1Style = 'font-medium text-3xl md:text-4xl mb-8';
  const subtitleStyle = 'text-xl md:text-2xl mb-6';

  return (
    <>
      <main>
        <Helmet>
          <title>Hummingbird - No nectar here</title>
          <meta
            name="description"
            content="We can't seem to find the page that you're looking for"
          />
        </Helmet>

        <NavBar />
        <div className="container py-28 text-center">
          <StaticImage
            src="../images/logo.svg"
            placeholder="blurred"
            height={200}
            quality={100}
            alt="Hummingbird Logo"
            className="mb-8"
          />
          <div>
            <h1 className={h1Style}>No nectar here</h1>
            <p className={subtitleStyle}>
              We can't seem to find the page you're looking for.
            </p>
            <Link to={HOME_ROUTE} className="font-bold">
              Fly home
            </Link>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default NotFoundPage;
